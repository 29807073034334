<template>
  <SectionCard direction="right">
    <template v-slot:sectionTitle>
      <h2>Come funziona <span> AdaptaEasy</span>? </h2>
    </template>
    <template v-slot:sectionContent>
      <p>
        Con Adapta easy, abbiamo digitalizzato delle procedure standardizzate
        che in pochi minuti permettono di gestire l’adattamento in modo più dolce e
        veloce, garantendo l’adattamento al 100%.
        Un kit contenente tutti gli strumenti necessari allo svolgimento delle
        procedure, su di un tavolo interattivo, due schermi digitali touch screen,
        una smart balance board connessa al sistema, e uno smart bracelet con i quali il cliente
        dovrà interagire per svolgere le procedure da protocollo,
        garantendo massima innovazione, interattività e una costumer experience
        unica per il cliente, senza dimenticare che abbiamo risolto il suo
        ed anche il nostro problema principale: IL MANCATO ADATTAMENTO ALL’USO
        DELLA LENTE PROGRESSIVA.
     </p>
    </template>
    <template v-slot:rightCol>
      <AnimatedImage
        v-bind:src="require('@/assets/img/grafics/how.jpg')"
        :style="'max-width: 100%; vertical-align: top ;'"
        animationClass="animate__zoomInRight"
      />
    </template>
  </SectionCard>
</template>

<script>
import SectionCard from '@/components/Cards/SectionCard.vue';
import AnimatedImage from '@/components/Images/AnimatedImage.vue';

export default {
  name: 'SystemSection',
  components: {
    SectionCard,
    AnimatedImage,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
  destroyed() {

  },
};

</script>
