<template>
    <section id="contactUs" class="section">
        <div class="container contact-section">
            <div class="row pt-4 d-block">
                <div clas="col-12 text-center">
                    <h2 class="contact-title text-center">
                        Vuoi <span style="color:#0071DC;">saperne di più </span>?
                    </h2>
                </div>
            </div>
            <div class="row ">
                <!--<div class="col-lg-3 col-2 d-md-block d-none align-middle">
                     <AnimatedImage
                        v-bind:src="require('@/assets/img/tree.svg')"
                        :style="'max-width: 100%;'"
                        animationClass="animate__zoomInLeft"
                        />
                </div>-->
                <div class="col-lg-6 col-md-8 col-12 ml-auto mr-auto">
                    <div class="card mt-5" :style="cssProps()">
                        <div class="card-body">
                            <div class="row">     
                                <div class="col-12 pd-5">
                                    <form ref="formX" @submit.prevent="sendEmail">
                                        <div class="form-row">
                                            <div class="form-group col-12">
                                                <label class="label-s" for="inputEmail4">Email</label>
                                                <input input type="email" v-model="email" name="email" class="form-control" id="inputEmail4" placeholder="Email">
                                            </div>
                                            <div class="form-group col-md-6 col-12">
                                                <label class="label-s" for="inputName">Nome</label>
                                                <input type="text" v-model="name" name="name" class="form-control" id="inputName" placeholder="Nome">
                                            </div>
                                            <div class="form-group  col-md-6 col-12">
                                                <label class="label-s" for="inputLastName">Cognome</label>
                                                <input type="text" v-model="lastname" name="lastname" class="form-control" id="inputLastName" placeholder="Cognome">
                                            </div>
                                            <div class="form-group col-12">
                                                <label class="label-s" for="inputCompany">Azienda</label>
                                                <input type="text" v-model="company" name="company" class="form-control" id="inputCompany" placeholder="Azienda">
                                            </div>
                                            <div class="form-group  col-12">
                                                <label class="label-s" for="inputCompinputmessageany">Testo</label>
                                                <textarea  type="text" v-model="message" name="message" class="form-control" id="inputCompany" placeholder="Scrivi ...."></textarea>
                                            </div>
                                            <div class="col-12">
                                                <button type="submit" class="btn btn-primary w-100 py-3">Invia</button>
                                                </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="col-lg-3 col-md-2 d-md-block d-none align-middle">
                    <AnimatedImage
                        v-bind:src="require('@/assets/img/tree.svg')"
                        :style="'max-width: 100% ;'"
                        animationClass="animate__zoomInRight"
                        />
                </div>-->
            </div>
        </div>
    </section>
</template>

<style>
.contact-section{
    background-image: url('../assets/img/contact-us.svg');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 50% 20%;
  }
  

  .label-s{
     font-weight:bold;
     font-size:1.1rem;
     color:#fff;
  }

  .contact-title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 0;
    font-weight: bold;
  }
</style>


<script>
import { ColorPalette } from '@/components/Mixins/ColorPalette.js';
import AnimatedImage from '@/components/Images/AnimatedImage.vue';
import emailjs from 'emailjs-com';
import Swal from "sweetalert2";




export default {
  name: 'ContactCard',
  components: {
    ColorPalette,
    AnimatedImage,
  },
  data() {
    return {
      cardColor: ColorPalette.palettes.dynamics,
      email: '',
      name: '',
      lastname: '',
      company: '',
      message: '',
      lastcontact: {
                    email: '',
                    name: '',
                    lastname: '',
                    company: '',
                    message: '',
                    },
    };
  },
  methods:{
      cssProps() {
        return `background-color: ${this.cardColor.orange}`;
      },
      sendEmail(event) {
        //console.log("kdddddddddddkdkdkdkdk");
        this.lastcontact.email= this.email;
        this.lastcontact.name= this.name;
        this.lastcontact.lastname= this.lastname;
        this.lastcontact.company= this.company;
        this.lastcontact.message= this.message;

        this.email='';
        this.name='';
        this.lastname='';
        this.company='';
        this.message='';



        emailjs.sendForm(
            'service_s7fmgki',
            'template_oqv2hvw',
             this.$refs.formX, 
            '1cNk8zhf1EBbhguHZ',
            {
                email: this.lastcontact.email,
                name:  this.lastcontact.name,
                lastname:  this.lastcontact.lastname,
                company:  this.lastcontact.company,
            }
        )
        .then(
            (result) => {
                console.log('SUCCESS', result.text);

                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Messaggio inviato",
                    showConfirmButton: false,
                    timer: 1000,
                });
            }, 
            (error) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Errore di invio",
                    showConfirmButton: false,
                    timer: 1000,
                });
                console.log('FAILED...', error.text);
            }
        );
    },
  },
};
</script>
