<template>
  <div id="app" class="main-wrapper">
    <!-- HEADER BEGIN -->
    <header id="headerTop" class="header min-header" style="height:120px;">
      <!-- NAVBAR BEGIN -->
      <nav class="navbar navbar-expand-lg header-nav" style="z-index:10001;">
        <div class="container">
          <div class="navbar-header">
            <!-- NAVBAR MOBILE BTN BEGIN -->
            <a
              id="mobile_btn"
              href="javascript:void(0);"
              v-on:click="openMobile()">
              <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <!-- NAVBAR MOBILE BTN END -->
            <!-- NAVBAR DESKTOP LOGO BEGIN -->
            <router-link to="/" class="navbar-brand logo">
              <img src="./assets/img/logo.png" class="img-fluid" alt="Logo">
            </router-link>
            <!-- NAVBAR DESKTOP LOGO END -->
        </div>

        <!-- NAVBAR MOBILE LOGO BEGIN -->
        <div class="main-menu-wrapper ml-0 mr-auto" style="text-align:left; ">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="./assets/img/logo.png" class="img-fluid" alt="Logo">
            </router-link>
            <a
              id="menu_close"
              class="menu-close"
              href="javascript:void(0);"
              v-on:click="closeMobile()"
            >
              <i class="feather-x"></i>
            </a>
         </div>
         <!-- NAVBAR MOBILE LOGO END -->

         <!-- NAVBAR MENU BEGIN -->
          <ul class="main-nav">
            <li>
              <router-link :to="{ path: '/' }">
                <i class="feather-home"></i> Home
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: '/#idea' }">
                <i class="feather-loader"></i> L'idea
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: '/#sistema' }">
                <i class="feather-cpu"></i> Il Sistema
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: '/#vantaggi' }">
                <i class="feather-plus"></i> Vantaggi
              </router-link>
            </li>
            <li>
              <router-link :to="{ path: '/#team' }">
                <i class="feather-users"></i>Team
              </router-link>
            </li>
            <!--
            <li>
              <router-link :to="{ path: '/' }">
                <i class="fas fa-plus"></i>Media e News
              </router-link>
            </li>
            -->
            <!--
            <li>
              <router-link :to="{ path: '/#newsletter' }">
                <i class="feather-bell"></i>Newsletter
              </router-link>
            </li>
            -->
             <li>
              <router-link :to="{ path: '/#contactUs' }">
                <i class="feather-mail"></i>Contattaci
              </router-link>
            </li>
          </ul>
          <!-- NAVBAR MENU END -->
        </div>
      </div>
      </nav>
    </header>
    <!-- HEADER END -->
    <!-- CONTENT BEGIN -->
    <router-view/>
    <!-- CONTENT END -->
    <!-- FOOTER BEGIN -->
    <footer class="footer">
     <!-- FOOTER-TOP BEGIN -->
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <!-- FOOTER-LOGO BEGIN -->
            <div class="col-lg-3 col-md-6">
              <div class="footer-widget footer-about text-center">
                <div class="footer-logo">
                  <img src="./assets/img/logo.png"
                    alt="logo" class="img-responsive"
                    style="max-width:85%"
                  />
                </div>
                <div class="footer-about-content text-center">
                  <div class="social-icon">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/adaptaeasy/" target="_blank">
                          <i class="feather-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://instagram.com/adapta_easy?igshid=YmMyMTA2M2Y=" target="_blank">
                          <i class="feather-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/channel/UC3qqhEz17ZZlb6sCKY-Y0UA/about" target="_blank">
                          <i class="feather-youtube"></i>
                        </a>
                      </li>
                       <!--<li>
                        <a href="#" target="_blank">
                          <i class="feather-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          <i class="feather-twitter"></i>
                        </a>
                      </li>-->
                    </ul>
                  </div>
                </div>
                <!-- FOOTER SOCIALS END -->
              </div>
            </div>
            <div class="col-lg-1 col-md-1">
              
            </div>
            <!-- FOOTER LOGO END -->

            <!-- FOOTER LINKS BEGIN -->
             <!--<div class="col-lg-3 col-md-6">
              <div class="footer-widget footer-menu">
                <h2 class="footer-title">Altri link</h2>
                <ul>
                  <li>
                    <router-link :to="{ name: 'clientArea'}">Area clienti</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'clientArea'}">Assistenza</router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'clientArea'}">Privacy Policy</router-link>
                  </li>
                </ul>
              </div>
            </div>-->
            <!-- FOOTER LINKS END -->
            <div class="col-lg-3 col-md-6">
              <div class="footer-widget footer-menu">
                <h2 class="footer-title">Social Media</h2>
                <ul>
                  <!--<li>
                    <a href="appointments.html">LinkedIn</a>
                  </li>-->
                  <li>
                    <a href="https://www.facebook.com/adaptaeasy/">Facebook</a>
                  </li>
                  <!--<li>
                    <a href="login.html">Twitter</a>
                  </li>-->
                  <li>
                    <a href="https://instagram.com/adapta_easy?igshid=YmMyMTA2M2Y=">Instagram</a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UC3qqhEz17ZZlb6sCKY-Y0UA/about">YouTube</a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- FOOTER CONTACTS BEGIN -->
            <div class="col-lg-3 col-md-6">
              <div class="footer-widget footer-contact">
                <h2 class="footer-title">Contatti</h2>
                <div class="footer-contact-info">
                  <div class="footer-address">
                    <span><i class="feather-map-pin"></i></span>
                    <p>
                      Via Carlo Magno 17 (207,85 km)
                      <br> 73042 Casarano, Puglia
                    </p>
                  </div>
                  <p>
                    <i class="feather-phone"></i>
                    <a href="tel:0833 501636">0833 501636</a>
                    
                  </p>
                </div>
              </div>
            </div>
            <!-- FOOTER CONTACTS END -->
          </div>
        </div>
      </div>
      <!-- FOOTER TOP END -->

      <!-- FOOTER BOTTOM BEGIN -->
      <div class="footer-bottom">
        <div class="container">
          <!-- FOOTER COPYRIGHT BEGIN -->
          <div class="copyright">
            <div class="row">
              <div class="col-md-6 col-lg-6">
                <div class="copyright-text">
                  <p class="mb-0">
                    &copy; 2021 Vysio s.r.l.
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-lg-6">
                <div class="policy-menu" style="">
                  <!--<ul class="policy-menu">
                    <li>
                      <a href="term-condition.html">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="privacy-policy.html">Policy</a>
                    </li>
                  </ul>-->
                <p>Powered by | TheGINLabs </p>
                </div>
              </div>
            </div>
          </div>
          <!-- FOOTER COPYRIGHT END -->
        </div>
      </div>
      <!-- FOOTER END -->
    </footer>
  </div>

</template>


<script>
export default {
  name: 'Main',
  methods: {
    openMobile() {
      //console.log(document.getElementsByClassName('main-wrapper'));
      document.getElementsByClassName('main-wrapper')[0].classList.toggle(
        'slide-nav'
      );
      document.documentElement.classList.add('menu-opened');
    },
    closeMobile() {
      document.getElementsByClassName('main-wrapper')[0].classList.remove(
        'slide-nav'
      );
      document.documentElement.classList.remove('menu-opened');
    },
    sidebarOverlayToggle() {
      document.getElementsByClassName('main-wrapper').classList.remove(
        'slide-nav'
      );
      document.documentElement.classList.remove('menu-opened');
    }
  }
}

</script>
