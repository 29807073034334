var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section section-newsletter",attrs:{"id":"newsletter"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-lg-7 col-md-5 col-12 social-bg py-4"},[_c('div',{staticClass:"row newsletter-detail"},[_c('div',{staticClass:"col-lg-4 col-md-1 col-2"}),_c('div',{staticClass:"col-lg-4 col-md-6 col-8 col-text-center pr-4"},[_c('a',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                  callback: _vm.isIgViewableNow,
                  once:true
              }),expression:"{\n                  callback: isIgViewableNow,\n                  once:true\n              }"}],staticClass:"btn social-icons social-icons-instagram btn-block btn-lg",class:{
                'button-visible animate__animated animate__bounceIn': _vm.showIg,
                'button-invisible' : !_vm.showIg
              },attrs:{"type":"button","href":"https://instagram.com/adapta_easy?igshid=YmMyMTA2M2Y=","target":"_blank"}},[_vm._m(1)]),_c('a',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                  callback: _vm.isFbViewableNow,
                  once:true
              }),expression:"{\n                  callback: isFbViewableNow,\n                  once:true\n              }"}],staticClass:"btn social-icons social-icons-facebook btn-block btn-lg",class:{
                'button-visible animate__animated animate__bounceIn': _vm.showFb,
                'button-invisible' : !_vm.showFb
              },staticStyle:{"background-color":"#3b5998"},attrs:{"type":"button ","href":"https://www.facebook.com/adaptaeasy/","target":"_blank"}},[_vm._m(2)]),_c('a',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                  callback: _vm.isYtViewableNow,
                  once:true
              }),expression:"{\n                  callback: isYtViewableNow,\n                  once:true\n              }"}],staticClass:"btn social-icons social-icons-youtube btn-block btn-lg",class:{
                'button-visible animate__animated animate__bounceIn': _vm.showYt,
                'button-invisible' : !_vm.showYt
              },attrs:{"type":"button","href":"https://www.youtube.com/channel/UC3qqhEz17ZZlb6sCKY-Y0UA/about","target":"_blank"}},[_vm._m(3)])]),_c('div',{staticClass:"col-lg-4 col-md-3 col-2"})])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-5 col-md-7 col-12"},[_c('div',{staticClass:"newsletter-detail"},[_c('div',{staticClass:"section-header"},[_c('h2',[_vm._v("Vuoi essere sempre "),_c('span',[_vm._v("aggiornato")]),_vm._v("?")]),_c('p',[_vm._v(" Segui le nostre pagine social e non perdere nessuna notizia del mondo Adapta Easy ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"adv-icon"},[_c('i',{staticClass:"feather-instagram"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"adv-icon"},[_c('i',{staticClass:"feather-facebook"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"adv-icon"},[_c('i',{staticClass:"feather-youtube"})])}]

export { render, staticRenderFns }