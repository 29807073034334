<template>
<section id="main"
  v-observe-visibility="{callback: isViewableNow, once: true}"
  class="section section-search pt-2 landing-box"
  :class="{ 'landing-visible animate__animated animate__bounceInDown' :
                showAnimation,
              'landing-invisible' : !showAnimation }"
  >
  <div class="container  ">
    <div class="row">
      <div class="col-lg-5 col-md-12 ml-auto mr-auto">
        <div class="banner-wrapper">
          <div class="banner-header">
            <h1 class="mb-0"><span class="text-blue"> AdaptaEasy:</span> il tutor</h1>
            <h1 class="mb-0"></h1>
            <h1 class="mb-0">al corretto utilizzo delle lenti</h1>
          </div>
          <p>
            Con il sistema brevettato Adapta Easy aiutiamo l’ottico
            optometrista a superare gli ostacoli relativi a tutte quelle lenti
            che necessitano di un <b>adattamento all’uso</b>, lenti progressive,
            lenti prismatiche e lenti con alte entità di cilindro.
            L’obiettivo è quello di garantire e gestire l’adattamento in modo
            più dolce e veloce.
          </p>
         </div>
         <div class="search-box">
          <router-link :to="{ path: '/#sistema' }"
            class="btn btn-primary subscribe-btn mt-2 mr-2"
            style="padding:12px 30px; font-weight:bold;"
          >
            Scopri di più
          </router-link>
          <router-link :to="{ path: '/#newsletter' }"
            class="btn btn subscribe-btn mt-2"
            style="padding:12px 30px; background-color:#fb503b; font-weight:bold; color:#fff;"
          >
            Resta aggiornato
          </router-link>
        </div>
     </div>
     <div class="col-lg-5  ml-auto mr-auto banner-img">
       <!--
        <img class="img-responsive d-lg-none d-block"
          src="../assets/img/vysio-test2.svg" style="max-width: 85%;
          vertical-align: top ; "
        />
        -->
    </div>
 </div>
</div>
</section>
</template>

<style>

@media (max-width: 768px){
  .landing-box{
    background:none;
    height:500px;
    z-index:10000;
  }
}

@media (min-width: 769px) and (max-width: 992px){
  .landing-box{
    background:none;
    height:400px;
    z-index:10000;
  }
}

  @media (min-width: 993px) and (max-width : 1200px) {
  .landing-box{
    /*background-image: url('../assets/img/vysio-test2.svg');*/
    background-image: url('../assets/img/grafics/why.jpg');
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 90% 45%;
    height:65vh;
    z-index:10000;
  }
}


@media (min-device-width : 1201px) {
  .landing-box{
    /*background-image: url('../assets/img/vysio-test2.svg');*/
    background-image: url('../assets/img/grafics/why.jpg');
    background-repeat: no-repeat;
    background-size: 38%;
    background-position: 80% 45%;
    height:760px;
    z-index:10000;
  }
}


.landing-visible { visibility: visible };
.landing-invisible { visibility: hidden };


</style>
<script>
export default {
  name: 'Landing',
  data() {
    return {
      showAnimation: false,
    };
  },
  methods: {
    isViewableNow(isVisible, entry) {
      this.showAnimation = isVisible;
    }
  },
  mounted() {

  },
};

</script>
