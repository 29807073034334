<template>
  <div class="container-fluid"
    v-observe-visibility="{ callback: isViewableNow, once: true }"
    :class="{
      'team-memeber-visible animate__animated animate__zoomIn'
      : showAnimation,
      'team-memeber-invisible' : !showAnimation
    }"
  >
    <div class="row">
      <div class="col-10   ml-auto mr-auto">
        <slot name="avatarImg">
        </slot>

      </div>
    </div>
    <div class="row mt-2">
      <div class="col-8 ml-auto mr-auto text-center">
        <h5>{{ name }}</h5>
        <p>{{ role }}</p>
      </div>
    </div>
  </div>
</template>

<style>
  .team-member-visible { visibility: visible; }
  .team-member-invisible { visibility: hidden; }
</style>

<script>
export default {
  name: 'TeamMemberCard',
  props: {
    name: String,
    role: String,
  },
  data() {
    return {
      showAnimation: false,
    };
  },
  methods: {
    isViewableNow(isVisible,entry) {
      this.showAnimation = isVisible;
    },
  },
  mounted() {
  }
}

</script>
