<template>

  <section
    class="section section-doctor pt-5 "
  >
    <div class="container px-4">
      <div class="row align-items-center">

        <div class="ml-auto col-lg-6 col-md-12 px-4 mb-4">

          <div class="row">
            <div class="col-12 text-center mb-4">
                <h2 class="target-title">
                  Vantaggi per l'<span style="color:#5c8497;">Optometrista</span>
                </h2>
            </div>
          </div>

          <div class="row" >

            <div class="col-lg-6 col-md-12 px-0 py-0 w-100 h-100">
              <SmallTile :backgroundColor='tilesColors.dirtyBlue'
                title="Fidelizzazione"
                icon="feather-heart"
                >
                <template v-slot:content>
                  <p :class="{ text: readMore1 }">
                    Spesso chi necessita di lenti progressive cambia ottico fino a trovare il professionista in grado di dargli la soluzione. Con adapta easy oltre alla soluzione del problema, si fa vivere al cliente un’esperienza d’acquisto unica ed interattiva che riconosce nel centro ottico un punto di riferimento all’avanguardia nel fornire soluzioni visive. Ad oggi 8 presbiti su 10 non hanno ancora trovato il loro punto di riferimento, perché non dovresti essere tu?
                    <span v-if="readMore1"></span>
                  </p>
                  <button class="btn btn-outline-dark btn-sm" @click="readMore1 =! readMore1">
                    <span v-if="readMore1">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </template>
              </SmallTile>
            </div>

            <div class="col-lg-6 col-md-12 px-0 py-0 w-100 h-100">
              <SmallTile :backgroundColor="tilesColors.blue"
                title="Gestione semplificata"
                icon="feather-calendar"
              >
                <template v-slot:content>
                  <p :class="{ text: readMore2 }">
                    Il sistema adapta easy, con le sue semplici procedure, ludico-interattive, schematizzate e standardizzate, è un sitema volto ad essere utilizzato da qualsiasi operatore che vive il centro ottico, (ottico-optometrista-commesso-collaboratore) a qualsiasi tipo di cliente che necessiti un extra training nell’adattamento alla lente progressiva, prismatica, o alti poteri di cilindro.
                    <span v-if="readMore2"></span>
                  </p>
                  <button class="btn btn-outline-dark btn-sm" @click="readMore2 =! readMore2">
                    <span v-if="readMore2">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </template>
              </SmallTile>
            </div>

            <div class="col-lg-6 col-md-12 px-0 py-0 w-100 h-100">
              <SmallTile :backgroundColor="tilesColors.blue"
               title="Training e support" icon="feather-headphones">
                <template v-slot:content>
                  <p :class="{ text: readMore3 }">
                    L’idea di fornire un supporto alla vendita è unica nel suo genere, ad oggi non esiste al mondo uno strumento che dia un vantaggio del genere nell “accompagnare” il cliente verso l’adattamento. Il training visivo così diventa un utile e professionalizzante supporto alla vendita di valore. Non ci saranno più alibi del cliente “e se poi non mi adatto” e da ottici sapremo dare molte più risposte alle domande: “mi piacerebbe prima provare
                    <span v-if="readMore3"></span>
                  </p>
                  <button class="btn btn-outline-dark btn-sm" @click="readMore3 =! readMore3">
                    <span v-if="readMore3">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </template>
              </SmallTile>
            </div>

            <div class="col-lg-6 col-md-12 px-0 py-0 w-100 h-100">
              <SmallTile :backgroundColor="tilesColors.dirtyBlue"
              title="Analisi dei risultati" icon="feather-bar-chart-2">
                <template v-slot:content>
                  <p :class="{ text: readMore4 }">
                    Il sistema è strutturato con delle attività a punteggio, di intensità e difficoltà variabili, a seconda di quelle che sono le abilità del cliente, che il software analizza elabora ed immagazina nel suo database, riuscendo a indicarci dove e  quali sono le lacune da implementare, (si tratta di ripetere l’esercizio) fino a portare il cliente ad essere un buon potenziale portatore.
                    <span v-if="readMore4"></span>
                  </p>
                  <button class="btn btn-outline-dark btn-sm" @click="readMore4 =! readMore4">
                    <span v-if="readMore4">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </template>
              </SmallTile>
            </div>

          </div>

        </div>

        <div class="mr-auto col-lg-6 col-md-12 px-4 mb-4">

          <div class="row">
            <div class="col-12 text-center mb-4">
                <h2 class="target-title">
                  Vantaggi per il <span style="color:#fb503b;">Cliente</span>
                </h2>
            </div>
          </div>

          <div class="row">

            <div class="col-lg-6 col-md-12 px-0 py-0 w-100 h-100">
              <SmallTile :backgroundColor='tilesColors.red'
                title="Esperienza unica"
                icon="feather-loader"
                >
                <template v-slot:content>
                  <p :class="{ text: readMore5 }">
                    I nostri clienti vivono l’esperienza della lente progressiva, con tutti i suoi pregi e i suoi difetti, in un’unicità di sistema fatto di interazione e giochi che metteranno alla prova le loro abilità visive. Il digitale mondo di adapta easy li porterà in   un’esperienza d’acquisto  mai  sperimentata fino ad oggi. Unito alla reale soluzione della loro probematica,  il percepito del nostro centro ottico da parte dei nostri clienti diventa così altissimo.
                    <span v-if="readMore5"></span>
                  </p>
                  <button class="btn btn-outline-dark btn-sm" @click="readMore5 =! readMore5">
                    <span v-if="readMore5">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </template>
              </SmallTile>
            </div>

            <div class="col-lg-6 col-md-12 px-0 py-0 w-100 h-100">
              <SmallTile :backgroundColor="tilesColors.orange"
                title="Monitoraggio"
                icon="feather-activity"
              >
                <template v-slot:content>
                  <p :class="{ text: readMore6 }">
                    Riuscirò ad adattarmi? La lente progressiva è la lente giusta per me? Avrò dei problemi nel porto della lente? La tecnologia adapta easy con il suo software è strutturato su un sistema di punteggio, quantitativo e qualitativo, ci farà capire dove i nostri clienti farebbero più fatica, e ci consente di far potenziare quell’area (si tratta di ripetere il gioco-esercizio) fino a quando il cliente non ha totale consapevolezza e controllo di cosa fare con la lente davanti agli occhi. Se una sessione non basta, chiedine un’altra al tuo ottico-optometrista, puoi ripartire dal gioco in cui incontravi più difficoltà e sfidare te stesso a far meglio!
                    <span v-if="readMore6"></span>
                  </p>
                  <button class="btn btn-outline-dark btn-sm" @click="readMore6 =! readMore6">
                    <span v-if="readMore6">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </template>
              </SmallTile>
            </div>

            <div class="col-lg-6 col-md-12 px-0 py-0 w-100 h-100">
              <SmallTile :backgroundColor="tilesColors.orange"
               title="Maggiore comfort" icon="feather-eye">
                <template v-slot:content>
                  <p :class="{ text: readMore7 }">
                    Una volta superate tutte le prove sarai pronto ad indossare l’occhiale progressivo senza nessun problema di discomfort, il cliente arriva preparato ad ogni insidia che la tecnologia oftalmica oggi ci pone davanti. Indossare i nuovi occhiali progressivi sarà un gioco da ragazzi. E i vecchi stereotipi sull’adattamento saranno superati, con grande riconoscenza verso il centro ottico che si è dimostrato attento, professionale e all’avanguardia in campo tecnologico.
                    <span v-if="readMore7"></span>
                  </p>
                  <button class="btn btn-outline-dark btn-sm" @click="readMore7 =! readMore7">
                    <span v-if="readMore7">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </template>
              </SmallTile>
            </div>

            <div class="col-lg-6 col-md-12 px-0 py-0 w-100 h-100">
              <SmallTile :backgroundColor="tilesColors.red"
              title="Performances" icon="feather-trending-up">
                <template v-slot:content>
                  <p :class="{ text: readMore8}">
                    Lo sapevi che il sistema visivo è un complesso apparato che lavora in simbiosi con tutte le sue capacità e abilità? La tecnologia di adapta easy punta ad aumentare le performances visive in quelle che a volte potrebbero essere lacune di alcune aree funzionali, o abilità, che non ci consentono di lavorare al 100%, e da li scaturiscono tante difficoltà che sembrano non avere una soluzione. Con le nostre procedure, andremo a potenziare quelle aree deboli, imparando il metodo, valutando i miglioramenti e gli avanzamenti, fino a che la lente oftalmica davanti ai nostri occhi non sarà più un problema.
                    <span v-if="readMore8"></span>
                  </p>
                  <button class="btn btn-outline-dark btn-sm" @click="readMore8 =! readMore8">
                    <span v-if="readMore8">Read Less</span>
                    <span v-else>Read More</span>
                  </button>
                </template>
              </SmallTile>
            </div>

          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<style>
  .target-title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 0;
    font-weight: bold;
  }
</style>
<script>
import SmallTile from '@/components/Cards/SmallTile.vue';
import { ColorPalette } from '@/components/Mixins/ColorPalette.js';

export default {
  name: 'AdvantagesSeciton',
  components: {
    SmallTile,
    ColorPalette,
  },
  data() {
    return {
      tilesColors: ColorPalette.palettes.dynamics,
      readMore1: true,
      readMore2: true,
      readMore3:true,
      readMore4:true,
      readMore5: true,
      readMore6: true,
      readMore7:true,
      readMore8:true,


    };

    

  },
  mounted() {
  },
};

</script>

<style>
  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* number of lines to show */
            line-clamp: 5; 
    -webkit-box-orient: vertical;
  }
</style>