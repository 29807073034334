<template>
<div id="main" class="container-fluid px-0">
  <Landing />
  <IdeaSection id="idea"/>
  <SystemSection id="sistema"/>
  <AdvantagesSeciton id="vantaggi"/>
  <TeamCard id="team"/>
  <ContactCard />
  <NewsletterForm id="newsletter"/>
</div>
</template>

<script>
import Landing from '@/components/Landing.vue';
import SystemSection from '@/components/SystemSection.vue';
import IdeaSection from '@/components/IdeaSection.vue';
import AdvantagesSeciton from '@/components/AdvantagesSection.vue';
import TeamCard from '@/components/TeamCard.vue';
import SocialMedia from '@/components/SocialMedia.vue';
import NewsletterForm from '@/components/NewsletterForm.vue';
import ContactCard from '@/components/ContactCard.vue';

export default {
  name: 'Home',
  components: {
    Landing,
    SystemSection,
    IdeaSection,
    AdvantagesSeciton,
    TeamCard,
    NewsletterForm,
    ContactCard,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>
