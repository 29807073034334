<template>
<div id="main" class="container-fluid px-0">
  <Landing />
  
</div>
</template>

<script>
import Landing from '@/components/Landing.vue';

export default {
  name: 'Home',
  components: {
    Landing,
    
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  destroyed() {
  },
};
</script>
