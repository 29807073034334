import Vue from 'vue';
import './assets/css/bootstrap.css';
import './assets/css/feather.css';
import './assets/css/style.css';
import './assets/css/custom-animations.css';
import 'animate.css';
import VueObserveVisibility from 'vue-observe-visibility'
import App from './App.vue';
import router from './router';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
Vue.config.productionTip = false;
Vue.use(VueObserveVisibility);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
