<template>
  <div
    v-observe-visibility="{ callback: isViewableNow, once: true}"
    class="card small-tile px-0 mb-0"
    :style="tileCssProps()"
    :class="{
      'small-tile-visible animate__animated animate__bounceIn' : showAnimation,
      'small-tile-invisible animate__animated' : !showAnimation
    }"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-12">
            <h5 class="small-tile-title">{{ title }}</h5>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-2" v-if="showIcon">
          <span
          v-observe-visibility="{ callback: isIconViewableNow, once: true }"
          :class="{
            'small-tile-icon-visible animate__animated animate__fadeIn' :
              showIconAnimation,
            'small-tile-icon-invisible' : !showIconAnimation
          }"
          style="animation-delay: 0.5s"
          class="small-tile-icon">
            <i :class="icon"></i>
          </span>
        </div>
        <div
          class="small-tile-text"
          :class="{ 'col-10' : showIcon, 'col-12' : !showIcon }"
        >
          <slot name="content">
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .small-tile {
  }
  .small-tile-visible { visibility: visible; }
  .small-tile-invisible { visibility: hidden; }
  .small-tile-title {
    font-size:1.1rem;
    color: #ffff;
  }

  .small-tile-icon {
    border-color:#ffff;
  }
  .small-tile-icon-visible { visibility: visible; }
  .small-tile-icon-invisible { visibility: hidden; }
  .small-tile-icon i {
    font-size:1.7rem;
  }
  .small-tile-text p {
    font-size:0.75rem;
  }

</style>


<script>
export default {
  name: 'SmallTile',
  props: {
    title: String,
    icon: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#5c8497'
    },
    textColor: {
      type: String,
      default: '#ffff',
    },
  },
  data() {
    return {
      title: this.$props.title,
      icon: this.$props.icon,
      showIcon: (this.$props.icon !== ''),
      showAnimation: false,
      showIconAnimation: false,
      randomAnimationDelay: {
        min: 0.05,
        max: 0.2,
      },
    }
  },
  methods: {
    isViewableNow(isVisible,entry) {
      this.showAnimation = isVisible;
    },
    isIconViewableNow(isVisible, entry) {
      this.showIconAnimation = isVisible;
    },
    tileCssProps() {
      return {
        'background-color': this.$props.backgroundColor,
        'color': this.$props.textColor,
        'animation-delay':
          `${Math.random() * (this.randomAnimationDelay.max
                            -this.randomAnimationDelay.min)
                            + this.randomAnimationDelay.min}s`,
      }
    }
  },
};
</script>
