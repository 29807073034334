<template>
    <span
      v-observe-visibility="{ callback: isViewableNow, once: true }"
      :class="getAnimationClass()"
      class="badge mb-2 ml-2 p-2" :style="cssProps"
    >
      {{ text }}
    </span>
</template>

<script>
export default {
  name: 'BigBadge',
  props: {
    textColor: String,
    backgroundColor: String,
    text: String,
    sequenceNumber: {
      type: Number,
      default: 0,
    },
    animationDuration: {
      type: Number,
      default: 2,
    },
    animationClass: {
      type: String,
      default: 'animate__bounceIn',
    },
  },
  data() {
    return {
      showAnimation: false,
    };
  },
  methods: {
    isViewableNow(isVisible, entry) {
      this.showAnimation = isVisible;
    },
    getAnimationClass() {
      const a =
        `big-badge-visible animate__animated ${this.$props.animationClass}`;
      const c = {
        [a]: this.showAnimation,
        'big-badge-visible' : !this.showAnimation
      };
      return c;
    },
  },
  computed: {
    cssProps() {
      return {
        color: this.$props.textColor,
        'background-color': this.$props.backgroundColor,
        'font-size': '1.125rem',
        '--animate-duration': `${this.$props.animationDuration}s`,
        '--animate-delay': `${(this.$props.sequenceNumber * 0.1)}s`,
      };
    },
  },
};
</script>

<style>
  .big-badge-visible { visibility: visible; }
  .big-badge-invisible { visibility: hidden; }
</style>
