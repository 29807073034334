<template>
  <section id="newsletter" class="section section-newsletter">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 col-md-7 col-12">
          <div class="newsletter-detail">
            <div class="section-header">
              <h2>Vuoi essere sempre <span>aggiornato</span>?</h2>
              <p>
                Segui le nostre pagine social e non perdere nessuna notizia del mondo Adapta Easy
              </p>
            </div>
            <!--<form action="#">
              <div class="newsletter">
                <div class="form-group mail-box">
                  <input
                  type="email" class="form-control"
                  placeholder="Inserisci il tuo indirizzo e-email"
                  />
                </div>
                <button
                  type="submit"
                  class="btn btn-primary subscribe-btn"
                >
                  Iscriviti
                </button>
              </div>
            </form>-->
          </div>
        </div>

        <div class="col-lg-7 col-md-5 col-12 social-bg py-4">
          <div class="row newsletter-detail">
            <div class="col-lg-4 col-md-1 col-2"></div>
            <div class="col-lg-4 col-md-6 col-8 col-text-center pr-4">
              <a type="button"
                v-observe-visibility="{
                    callback: isIgViewableNow,
                    once:true
                }"
                :class="{
                  'button-visible animate__animated animate__bounceIn': showIg,
                  'button-invisible' : !showIg
                }"
                class="btn social-icons social-icons-instagram btn-block btn-lg"
                href="https://instagram.com/adapta_easy?igshid=YmMyMTA2M2Y="
                target="_blank"
                >
                <span class="adv-icon">
                    <i class="feather-instagram"></i>
                </span>
              </a>
              <a
                type="button "
                class="btn social-icons social-icons-facebook btn-block btn-lg"
                style="background-color: #3b5998"
                href="https://www.facebook.com/adaptaeasy/"
                target="_blank"
                v-observe-visibility="{
                    callback: isFbViewableNow,
                    once:true
                }"
                :class="{
                  'button-visible animate__animated animate__bounceIn': showFb,
                  'button-invisible' : !showFb
                }">
                <span class="adv-icon">
                    <i class="feather-facebook"></i>
                </span>
              </a>
              <a
                type="button"
                class="btn social-icons social-icons-youtube btn-block btn-lg"
                href="https://www.youtube.com/channel/UC3qqhEz17ZZlb6sCKY-Y0UA/about"
                target="_blank"
                v-observe-visibility="{
                    callback: isYtViewableNow,
                    once:true
                }"
                :class="{
                  'button-visible animate__animated animate__bounceIn': showYt,
                  'button-invisible' : !showYt
                }"
                >
                <span class="adv-icon">
                    <i class="feather-youtube"></i>
                </span>
              </a>
            </div>
            <div class="col-lg-4 col-md-3 col-2"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>

.social-icons {
    color:#fff;
    font-weight:bold;


}

@media (min-width: 768px) {
  .social-bg{
    background-image: url('../assets/img/vysio-social-background.svg');
    background-position: right;
    background-size: 100%;
    background-repeat:   no-repeat;
    background-position: 100% 45%;
  }
}
@media (max-width: 768px) {
  .social-bg{
    background:none;
  }
}

.button-visible { visibility: visible };
.button-invisible { visibility: invisible };

.social-icons-facebook{
    background-color:#3b5998;
}

.social-icons-instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );

}

.social-icons-youtube {
   background-color:#FF0000;
}


</style>

<script>

export default {
  name: 'NewsletterForm',
  data() {
    return {
      showIg: false,
      showFb: false,
      showYt: false,
    };
  },
  methods: {
    isIgViewableNow(isVisible,entry) {
      this.showIg = isVisible;
    },
    isFbViewableNow(isVisible,entry) {
      this.showFb = isVisible;
    },
    isYtViewableNow(isVisible,entry) {
      this.showYt = isVisible;
    },
  },
};
</script>
