import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import ClientArea from '../views/ClientArea.vue';
import Assistance from '../views/Assistance.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/clientArea',
    name: 'ClientArea',
    component: ClientArea,
  },
  {
    path: '/assistance',
    name: 'Assistance',
    component: Assistance,
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if(savedPosition) {
      //console.log('Saved position...',savedPosition);
      return savedPosition;
    } else if (to.hash) {
      //console.log('Scrolling to hash...',to.hash);
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      //console.log('Scrolling to top...');
      return {x: 0, y:0, behavior:'smooth'};
    }
  },
});

export default router;
