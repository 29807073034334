<template>
  <section id="team" class="section section-features team-box">
    <div class="container-fluid">
      <div class="row">
        <div class="col-8 ml-auto mr-auto text-center ">
          <div class="section-header ">
            <h2>Il nostro <span>Team </span></h2>
          </div>
        </div>
        <div class="col-lg-8 ml-lg-auto mr-lg-auto col-md-12">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-4 col-md-11 ml-auto">
                <!--
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-10   ml-auto mr-auto">
                      <img
                        src="../assets/img/luigi_secli_mod.jpg"
                        class="img-responsive rounded-circle"
                        style="max-width: 100%; vertical-align: top ; "
                        />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-8 ml-auto mr-auto text-center">
                      <h5>Prof. Luigi Seclì</h5>
                      <p>Ottico Optometrista</p>
                    </div>
                  </div>
                </div>
                -->
                <TeamMemberCard
                  name="Prof. Luigi Seclì"
                  role="Ottico Optometrista"
                >
                <template v-slot:avatarImg>
                <img
                  v-bind:src="require('@/assets/img/luigi_secli_mod.jpg')"
                  class="img-responsive rounded-circle"
                  style="max-width: 100%; vertical-align: top ; "
                  />
                  </template>
                </TeamMemberCard>
              </div>
              <div class="col-lg-4 col-md-11 ml-auto">
                <!--
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-10 ml-auto mr-auto">
                      <img
                        src="../assets/img/marco_secli_mod.jpg"
                        class="img-responsive rounded-circle"
                        style="max-width: 100%; vertical-align: top ; "
                        />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-8 ml-auto mr-auto text-center">
                      <h5>Dott. Marco Seclì</h5>
                      <p>Ottico Optometrista</p>
                    </div>
                  </div>
                </div>
                -->
                <TeamMemberCard
                  name="Dott. Marco Seclì"
                  role="Ottico Optometrista"
                >
                <template v-slot:avatarImg>
                <img
                  v-bind:src="require('@/assets/img/marco_secli_mod.jpg')"
                  class="img-responsive rounded-circle"
                  style="max-width: 100%; vertical-align: top ; "
                  />
                </template>
                </TeamMemberCard>
              </div>
              <div class="col-lg-4 col-11 ml-auto">
                <!--
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-10 ml-auto mr-auto">
                      <img
                        src="../assets/img/andrea_secli_mod.jpg"
                        class="img-responsive rounded-circle"
                        style="max-width: 100%; vertical-align: top ; "
                        />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-8 ml-auto mr-auto text-center">
                      <h5>Dott. Andrea Seclì</h5>
                      <p>Business Developer</p>
                    </div>
                  </div>
                </div>
                -->
                <TeamMemberCard
                  name="Dott. Andrea Seclì"
                  role="Business Developer"
                >
                <template v-slot:avatarImg>
                <img
                  v-bind:src="require('@/assets/img/andrea_secli_mod.jpg')"
                  class="img-responsive rounded-circle"
                  style="max-width: 100%; vertical-align: top ; "
                  />
                </template>
                </TeamMemberCard>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="col-lg-3">
          <img
          src="../assets/img/team.svg" class="img-responsive"
          style="max-width: 85%; vertical-align: top ; " />
        </div>
        -->
      </div>
    </div>
  </section>
</template>

<style>

  @media (min-width: 993px)  {
    .team-box{
      /*background-image: url('../assets/img/team-beckground.svg');*/
      background-repeat: no-repeat;
      background-size: 65%;
      background-position: 50% 45%;
    }
  }

  @media (max-width: 992px){
    .landing-box{
      background:none;
    }
  }

  

</style>

<script>
import TeamMemberCard from '@/components/Cards/TeamMemberCard.vue';

export default {
  name: 'TeamCard',
  components: {
    TeamMemberCard,
  }
};
</script>
