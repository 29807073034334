<template>
<section id="social" class="section" >
    <div class="container-fluid social-bg " >
        <div class="row mt-auto  pt-5 pb-5 align-items-center" >
            <div class="col-1"></div>
            <div class="col-lg-2 text-center">
                <a type="button"  href="https://instagram.com/adapta_easy?igshid=YmMyMTA2M2Y=" class="btn social-icons social-icons-instagram btn-block btn-lg">
                    <span class="adv-icon">
                        <i class="feather-instagram"></i>
                    </span> 
                </a>
            </div>

            <div class="col-2"></div>

             <div class="col-lg-2 text-center">
                <a type="button " href="https://www.facebook.com/adaptaeasy/"  class="btn social-icons social-icons-facebook btn-block btn-lg">
                    <span class="adv-icon">
                        <i class="feather-facebook"></i>
                    </span> 
                </a>
            </div>

            <div class="col-2"></div>

             <div class="col-lg-2 text-center">
                <a type="button" href="#" class="btn social-icons social-icons-youtube btn-block btn-lg" >
                    <span class="adv-icon">
                        <i class="feather-youtube"></i>
                    </span> 
                </a>
            </div>
            
            <div class="col-1"></div>

        </div>
    </div>
</section>
</template>

<style>

.social-bg{
    background-image: url('../assets/img/vysio-social-background.svg');
    
    
}
.social-icons {
    color:#fff;
    font-weight:bold;

}

.social-icons-facebook{
    background-color:#3b5998
}

.social-icons-instagram {
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );

}

.social-icons-youtube {
   background-color:#FF0000
}


</style>
<script>
export default{
    name:'SocialMedia',
};
</script>