var ColorPalette = {
  palettes: {
    logo: {
      blue:'#84acd7',
      dirtyBlue: '#5c8497',
      lightCyan: '#e67ff',
      cyan: '#ceeff',
      white: '#ffffff',
    },
    dynamics : {
      blue: '#84acd7',
      cyan: '#80d9ff',
      red: '#fb503b',
      orange: '#fb913b',
      lime: '#8bbe1b',
      white: '#ffff',
    },
  }
};

export { ColorPalette };
