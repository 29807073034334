<template>
    <section
      ref="sectionRoot"
      class="section section-doctor mt-4 pt-1"
      v-observe-visibility="{callback: isSectionViewable, once: true}"
      :class="{
        'section-visible animate__animated animate__fadeInRight' :
          (showAnimation && $props.enableAnimation),
        'section-invisible ' :
            (!showAnimation && $props.enableAnimation),
        'section-visible' : (!$props.showAnimation)
      }"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 ml-auto pl-0" v-if="leftToRight">
            <slot name="leftCol">
            </slot>
          </div>

          <div class="col-lg-6 mt-auto mb-auto "
            :class="{ 'ml-auto' : leftToRight, '' : rightToLeft }">
            <div class="section-header">
              <slot name="sectionTitle">
              </slot>
            </div>
            <slot name="sectionContent">
            </slot>
          </div>

          <div class="col-lg-4 ml-auto pr-0" v-if="rightToLeft">
            <slot name="rightCol">
            </slot>
          </div>

        </div>
        <slot name="additionalContent">
        </slot>
      </div>
    </section>
</template>

<style>
  .view-full {
    height:100vh;
  }
  .section-visible {
    visibility: visible;
    opacity: 1;
  }
  .section-invisible {
    visibility: hidden;
    opacity: 0;
  }
</style>

<script>

export default {
  name: 'SectionCard',
  props: {
    direction: {
      type: String,
      default: 'left',
    },
    enableAnimation: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      leftToRight: (this.$props.direction === 'left'),
      rightToLeft: (this.$props.direciton !== 'left'),
      shown: true,
      observer: null,
      viewFull: false,
      inView: false,
      lastScrollY: 0,
      showAnimation: false,
    };
  },
  methods: {
    onScroll() {
      const rect = this.$refs.sectionRoot.getBoundingClientRect();
      const viewportHeight = window.innerHeight
        || document.documentElement.clientHeight;
      let intersect = 1;
      const st = window.pageYOffset || document.documentElement.scrollTop;
      // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > this.lastScrollY) {
        // Scroll down
        if (rect.y > 0 && rect.y < viewportHeight) {
          // Entering viewport top->bottom
          intersect = (viewportHeight - rect.y) / viewportHeight;
          if (intersect > 0.1) {
            if (this.inView === false) {
              console.log('Entering top -> bottom');
              if (this.shown === false) {
                this.shown = true;
                this.viewFull = false;
              }
              this.inView = true;
            }
          }
        } else if (rect.y < 0 && rect.bottom < viewportHeight) {
          // Leaving top->bottom or entering bottom->top
          intersect = rect.bottom / viewportHeight;
          if (intersect < 0.3) {
            if (this.inView === true) {
              console.log('Leaving top -> bottom');
              window.scrollBy({ top: rect.bottom, behavior: 'smooth' });
              this.inView = false;
            }
          }
        }
      } else if (rect.y < 0 && rect.bottom < viewportHeight) {
        // Scroll up
        intersect = rect.bottom / viewportHeight;
        if (intersect > 0.3) {
          if (this.inView === false) {
            console.log('Entering bottom -> top');
            window.scrollBy({ top: rect.top, behavior: 'smooth' });
            this.inView = true;
          }
        }
      } else if (rect.y > 0 && rect.y > viewportHeight && rect.bottom < 0) {
        // Leaving
        intersect = rect.y / viewportHeight;
        if (intersect < 0.3) {
          if (this.inView === true) {
            console.log('Leaving bottom -> top');
            window.scrollBy({ top: rect.top, behavior: 'smooth' });
            this.inView = false;
          }
        }
      }
      this.lastScrollY = st <= 0 ? 0 : st;
    },
    isSectionViewable(isVisible,entry) {
      if (this.showAnimation === false && this.$props.enableAnimation === true) {
        this.showAnimation = isVisible;
      }
    },
  },
  created() {
    this.leftToRight = (this.$props.direction === 'left');
    this.rightToLeft = (this.$props.direciton !== 'left');
  },
  mounted() {
    /*
    window.addEventListener('scroll', this.onScroll);
    */
  },
  destroyed() {
    /*
    this.observer.unobserve(this.$refs.sectionRoot);
    */
  },
};

</script>
