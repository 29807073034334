<template>
  <canvas ref="pieChart" class="styled-pie"></canvas>
</template>
<style>
  .styled-pie {
    max-width:80%;
  }
</style>
<script>
import Chart from 'chart.js';

export default {
  props: {
    chartId: String,
    chartData: Array,
    chartLabels: Array,
    chartColors: Array,
  },
  methods: {
    createChart() {
      const config = {
        type: 'doughnut',
        data: {
          labels: this.$props.chartLabels,
          datasets: [{
            data: this.$props.chartData,
            backgroundColor: this.$props.chartColors,
          }],
        },
        options: {
          legend: {
            display: true,
            position: 'right',
          },
          responsive: true,
        },
      };
      const c = new Chart(
        this.$refs.pieChart,
        config,
      );
      c.options.plugins.legend.position = 'right';
      c.draw();
    },
  },
  mounted() {
    this.createChart();
  },
};
</script>
