<template>
  <SectionCard >
    <template v-slot:leftCol>
      <!--
      <transition appear name="image-grow">
        <img class="img-responsive"
          src="../assets/img/vysio-idea.svg"
          style="max-width: 100%; vertical-align: top ; "
        />
        </transition>
      -->
      <AnimatedImage     

        v-bind:src="require('@/assets/img/grafics/idea.jpg')"
        :style="'max-width: 100%; vertical-align: top ;'"
        animationClass="animate__zoomInLeft"
      />
    </template>
    <template v-slot:sectionTitle>
      <h2>Da dove nasce l'<span>idea </span>?</h2>
    </template>
    <template v-slot:sectionContent>
      <p>
        Il modo di «vedere» di una persona è il risultato di anni di sviluppo,
        tutti noi formiamo degli schemi motori e sensoriali che diventano
        automatici per darci efficienza ed efficacia.
        Nel momento in cui mettiamo una lente davanti agli occhi tutto quello
        che abbiamo imparato viene messo in forse.
        Tutto ciò è amplificato quando mettiamo una lente progressiva davanti
        agli occhi, in quanto intrinsecamente altera diversi aspetti
        percettivi:
        <br/>
        <br/>
        <BigBadge
          sequenceNumber="1"
          text="percezione periferica"
          textColor="white"
          backgroundColor="#84acd7" />
        <BigBadge
          sequenceNumber="2"
          text="saccadi"
          textColor="white"
          backgroundColor="#fb913b" />
        <BigBadge
          sequenceNumber="3"
          text="inseguimenti"
          textColor="white"
          backgroundColor="#80d9ff" />
        <BigBadge
          sequenceNumber=4
          text="vergenze"
          textColor="white"
          backgroundColor="#fb913b" />
        <BigBadge
          sequenceNumber=5
          text="messa a fuoco"
          textColor="white"
          backgroundColor="#fb644f" />
        <BigBadge
          sequenceNumber=6
          text="stima delle distanze"
          textColor="white"
          backgroundColor="#80d9ff" />
        <BigBadge
          sequenceNumber=6
          text="stima delle dimensioni"
          textColor="white"
          backgroundColor="#fb644f" />
        <BigBadge
          sequenceNumber=7
          text="programmazione del movimento"
          textColor="white"
          backgroundColor="#84acd7" />
      </p>
    </template>
    <template v-slot:additionalContent>
      <div class="row align-items-center mt-4">
        <div class="col-lg-6 ml-auto mr-auto mt-auto mb-auto">
          <p>
            In Italia le ultime stime di SOI (Società Oftalmologica Italiana)
            parlano di 28 milioni di presbiti, circa il 50% della popolazione.
            Di contro, nonostante risultiamo uno dei Paesi più vecchi al mondo
            insieme al Giappone, la penetrazione della vendita di Lenti
            Progressive si attesta - secondo i dati GFK - al di sotto del 20%.
            <br/>
            <br/>
            <b>Per quale motivo 8 italiani (presbiti) su 10 non scelgono la lente
            progressiva? </b> <br/>
            <i>Perché esistono dei pregiudizi nei confronti della lente
            progressiva, dovuti quasi nella totalità dei casi al
            mancato adattamento. Il sistema Adapta Easy nasce proprio per
            eliminare questi pregiudizi e rendere l’adattamento alla portata di
            tutti.</i>
          </p>
        </div>
        <div class="col-lg-6 ml-auto mr-auto mt-auto mb-auto">
          <PieChart
            :chart-data="dataColleciton"
            :id="chartId"
            :chart-labels="dataLabels"
            :chart-colors="dataColors"
          ></PieChart>
        </div>
      </div>
    </template>
  </SectionCard>
</template>

<script>
import PieChart from '@/components/Charts/PieChart.vue';
import SectionCard from '@/components/Cards/SectionCard.vue';
import BigBadge from '@/components/Badges/BigBadge.vue';
import AnimatedImage from '@/components/Images/AnimatedImage.vue';

export default {
  name: 'IdeaSection',
  components: {
    PieChart,
    SectionCard,
    BigBadge,
    AnimatedImage,
  },
  data() {
    return {
      dataColleciton: [10, 40, 50],
      dataLabels: [
        'Presbiti: Lenti progressive SI',
        'Presbiti: Lenti progressive NO',
        'Non presbiti',
      ],
      dataColors: [
        '#fb913b',
        'rgb(128, 217, 255)',
        '#0079b2',
      ],
      chartId: 'optichart',
      badges: [
        {
          textColor: 'white',
          backgroundColor: '#84acd7',
          text: 'percezione periferica',
        },
        {
          textColor: 'white',
          backgroundColor: '#fb913b',
          text: 'saccadi',
        },
        {
          textColor: 'white',
          backgroundColor: '#80d9ff',
          text: 'inseguimenti',
        },
        {
          textColor: 'white',
          backgroundColor: '#fb913b',
          text: 'vergenze',
        },
        {
          textColor: 'white',
          backgroundColor: '#fb644f',
          text: 'messa a fuoco',
        },
        {
          textColor: 'white',
          backgroundColor: '#80d9ff',
          text: 'stime delle distanze',
        },
        {
          textColor: 'white',
          backgroundColor: '#fb644f',
          text: 'stime delle dimensioni',
        },
        {
          textColor: 'white',
          backgroundColor: '#84acd7',
          text: 'programmazione delle dimensioni',
        },
      ],
    };
  },
  methods: {

  },
  mounted() {
  },
  destroyed() {

  },
};
</script>
